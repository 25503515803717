// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { demos as dm0, frontmatter as fm0, toc as toc0, texts as txt0 } from '/Users/zhuziyi/github/technical-notes/docs/notes/react-method-calls-between-parent-and-child-components.md?type=meta';
import { demos as dm1, frontmatter as fm1, toc as toc1, texts as txt1 } from '/Users/zhuziyi/github/technical-notes/docs/guide/conventional-routing.md?type=meta';
import { demos as dm2, frontmatter as fm2, toc as toc2, texts as txt2 } from '/Users/zhuziyi/github/technical-notes/docs/guide/project-structure.md?type=meta';
import { demos as dm3, frontmatter as fm3, toc as toc3, texts as txt3 } from '/Users/zhuziyi/github/technical-notes/docs/interview/question-list.md?type=meta';
import { demos as dm4, frontmatter as fm4, toc as toc4, texts as txt4 } from '/Users/zhuziyi/github/technical-notes/docs/interview/architecture.md?type=meta';
import { demos as dm5, frontmatter as fm5, toc as toc5, texts as txt5 } from '/Users/zhuziyi/github/technical-notes/docs/notes/linux-firewalld.md?type=meta';
import { demos as dm6, frontmatter as fm6, toc as toc6, texts as txt6 } from '/Users/zhuziyi/github/technical-notes/docs/guide/auto-api-table.md?type=meta';
import { demos as dm7, frontmatter as fm7, toc as toc7, texts as txt7 } from '/Users/zhuziyi/github/technical-notes/docs/guide/mobile-library.md?type=meta';
import { demos as dm8, frontmatter as fm8, toc as toc8, texts as txt8 } from '/Users/zhuziyi/github/technical-notes/docs/interview/write-code.md?type=meta';
import { demos as dm9, frontmatter as fm9, toc as toc9, texts as txt9 } from '/Users/zhuziyi/github/technical-notes/docs/interview/algorithm.md?type=meta';
import { demos as dm10, frontmatter as fm10, toc as toc10, texts as txt10 } from '/Users/zhuziyi/github/technical-notes/docs/interview/js-basics.md?type=meta';
import { demos as dm11, frontmatter as fm11, toc as toc11, texts as txt11 } from '/Users/zhuziyi/github/technical-notes/docs/interview/react-vue.md?type=meta';
import { demos as dm12, frontmatter as fm12, toc as toc12, texts as txt12 } from '/Users/zhuziyi/github/technical-notes/docs/notes/array&object.md?type=meta';
import { demos as dm13, frontmatter as fm13, toc as toc13, texts as txt13 } from '/Users/zhuziyi/github/technical-notes/docs/guide/page-config.md?type=meta';
import { demos as dm14, frontmatter as fm14, toc as toc14, texts as txt14 } from '/Users/zhuziyi/github/technical-notes/docs/interview/network.md?type=meta';
import { demos as dm15, frontmatter as fm15, toc as toc15, texts as txt15 } from '/Users/zhuziyi/github/technical-notes/docs/notes/linux-issue.md?type=meta';
import { demos as dm16, frontmatter as fm16, toc as toc16, texts as txt16 } from '/Users/zhuziyi/github/technical-notes/docs/guide/initialize.md?type=meta';
import { demos as dm17, frontmatter as fm17, toc as toc17, texts as txt17 } from '/Users/zhuziyi/github/technical-notes/docs/guide/write-demo.md?type=meta';
import { demos as dm18, frontmatter as fm18, toc as toc18, texts as txt18 } from '/Users/zhuziyi/github/technical-notes/docs/interview/client.md?type=meta';
import { demos as dm19, frontmatter as fm19, toc as toc19, texts as txt19 } from '/Users/zhuziyi/github/technical-notes/docs/interview/layout.md?type=meta';
import { demos as dm20, frontmatter as fm20, toc as toc20, texts as txt20 } from '/Users/zhuziyi/github/technical-notes/docs/interview/server.md?type=meta';
import { demos as dm21, frontmatter as fm21, toc as toc21, texts as txt21 } from '/Users/zhuziyi/github/technical-notes/docs/guide/upgrading.md?type=meta';
import { demos as dm22, frontmatter as fm22, toc as toc22, texts as txt22 } from '/Users/zhuziyi/github/technical-notes/docs/interview/guide.md?type=meta';
import { demos as dm23, frontmatter as fm23, toc as toc23, texts as txt23 } from '/Users/zhuziyi/github/technical-notes/docs/interview/index.md?type=meta';
import { demos as dm24, frontmatter as fm24, toc as toc24, texts as txt24 } from '/Users/zhuziyi/github/technical-notes/docs/notes/linxu-vim.md?type=meta';
import { demos as dm25, frontmatter as fm25, toc as toc25, texts as txt25 } from '/Users/zhuziyi/github/technical-notes/docs/guide/markdown.md?type=meta';
import { demos as dm26, frontmatter as fm26, toc as toc26, texts as txt26 } from '/Users/zhuziyi/github/technical-notes/docs/guide/page-tab.md?type=meta';
import { demos as dm27, frontmatter as fm27, toc as toc27, texts as txt27 } from '/Users/zhuziyi/github/technical-notes/docs/notes/cli-yarn.md?type=meta';
import { demos as dm28, frontmatter as fm28, toc as toc28, texts as txt28 } from '/Users/zhuziyi/github/technical-notes/docs/notes/cli-npm.md?type=meta';
import { demos as dm29, frontmatter as fm29, toc as toc29, texts as txt29 } from '/Users/zhuziyi/github/technical-notes/docs/notes/webpack.md?type=meta';
import { demos as dm30, frontmatter as fm30, toc as toc30, texts as txt30 } from '/Users/zhuziyi/github/technical-notes/docs/notes/vscode.md?type=meta';
import { demos as dm31, frontmatter as fm31, toc as toc31, texts as txt31 } from '/Users/zhuziyi/github/technical-notes/docs/guide/index.md?type=meta';
import { demos as dm32, frontmatter as fm32, toc as toc32, texts as txt32 } from '/Users/zhuziyi/github/technical-notes/docs/notes/array.md?type=meta';
import { demos as dm33, frontmatter as fm33, toc as toc33, texts as txt33 } from '/Users/zhuziyi/github/technical-notes/docs/notes/index.md?type=meta';
import { demos as dm34, frontmatter as fm34, toc as toc34, texts as txt34 } from '/Users/zhuziyi/github/technical-notes/docs/words/index.md?type=meta';
import { demos as dm35, frontmatter as fm35, toc as toc35, texts as txt35 } from '/Users/zhuziyi/github/technical-notes/docs/guide/i18n.md?type=meta';
import { demos as dm36, frontmatter as fm36, toc as toc36, texts as txt36 } from '/Users/zhuziyi/github/technical-notes/docs/guide/faq.md?type=meta';
import { demos as dm37, frontmatter as fm37, toc as toc37, texts as txt37 } from '/Users/zhuziyi/github/technical-notes/docs/notes/git.md?type=meta';
import { demos as dm38, frontmatter as fm38, toc as toc38, texts as txt38 } from '/Users/zhuziyi/github/technical-notes/docs/notes/vue.md?type=meta';
import { demos as dm39, frontmatter as fm39, toc as toc39, texts as txt39 } from '/Users/zhuziyi/github/technical-notes/docs/words/cn.md?type=meta';
import { demos as dm40, frontmatter as fm40, toc as toc40, texts as txt40 } from '/Users/zhuziyi/github/technical-notes/docs/words/en.md?type=meta';
import { demos as dm41, frontmatter as fm41, toc as toc41, texts as txt41 } from '/Users/zhuziyi/github/technical-notes/docs/index.md?type=meta';

export { components } from './atoms';
export { tabs } from './tabs';

export const filesMeta = {
  'docs/notes/react-method-calls-between-parent-and-child-components': {
    frontmatter: fm0,
    toc: toc0,
    texts: txt0,
    demos: dm0,
  },
  'docs/guide/conventional-routing': {
    frontmatter: fm1,
    toc: toc1,
    texts: txt1,
    demos: dm1,
  },
  'docs/guide/project-structure': {
    frontmatter: fm2,
    toc: toc2,
    texts: txt2,
    demos: dm2,
  },
  'docs/interview/question-list': {
    frontmatter: fm3,
    toc: toc3,
    texts: txt3,
    demos: dm3,
  },
  'docs/interview/architecture': {
    frontmatter: fm4,
    toc: toc4,
    texts: txt4,
    demos: dm4,
  },
  'docs/notes/linux-firewalld': {
    frontmatter: fm5,
    toc: toc5,
    texts: txt5,
    demos: dm5,
  },
  'docs/guide/auto-api-table': {
    frontmatter: fm6,
    toc: toc6,
    texts: txt6,
    demos: dm6,
  },
  'docs/guide/mobile-library': {
    frontmatter: fm7,
    toc: toc7,
    texts: txt7,
    demos: dm7,
  },
  'docs/interview/write-code': {
    frontmatter: fm8,
    toc: toc8,
    texts: txt8,
    demos: dm8,
  },
  'docs/interview/algorithm': {
    frontmatter: fm9,
    toc: toc9,
    texts: txt9,
    demos: dm9,
  },
  'docs/interview/js-basics': {
    frontmatter: fm10,
    toc: toc10,
    texts: txt10,
    demos: dm10,
  },
  'docs/interview/react-vue': {
    frontmatter: fm11,
    toc: toc11,
    texts: txt11,
    demos: dm11,
  },
  'docs/notes/array&object': {
    frontmatter: fm12,
    toc: toc12,
    texts: txt12,
    demos: dm12,
  },
  'docs/guide/page-config': {
    frontmatter: fm13,
    toc: toc13,
    texts: txt13,
    demos: dm13,
  },
  'docs/interview/network': {
    frontmatter: fm14,
    toc: toc14,
    texts: txt14,
    demos: dm14,
  },
  'docs/notes/linux-issue': {
    frontmatter: fm15,
    toc: toc15,
    texts: txt15,
    demos: dm15,
  },
  'docs/guide/initialize': {
    frontmatter: fm16,
    toc: toc16,
    texts: txt16,
    demos: dm16,
  },
  'docs/guide/write-demo': {
    frontmatter: fm17,
    toc: toc17,
    texts: txt17,
    demos: dm17,
  },
  'docs/interview/client': {
    frontmatter: fm18,
    toc: toc18,
    texts: txt18,
    demos: dm18,
  },
  'docs/interview/layout': {
    frontmatter: fm19,
    toc: toc19,
    texts: txt19,
    demos: dm19,
  },
  'docs/interview/server': {
    frontmatter: fm20,
    toc: toc20,
    texts: txt20,
    demos: dm20,
  },
  'docs/guide/upgrading': {
    frontmatter: fm21,
    toc: toc21,
    texts: txt21,
    demos: dm21,
  },
  'docs/interview/guide': {
    frontmatter: fm22,
    toc: toc22,
    texts: txt22,
    demos: dm22,
  },
  'docs/interview/index': {
    frontmatter: fm23,
    toc: toc23,
    texts: txt23,
    demos: dm23,
  },
  'docs/notes/linxu-vim': {
    frontmatter: fm24,
    toc: toc24,
    texts: txt24,
    demos: dm24,
  },
  'docs/guide/markdown': {
    frontmatter: fm25,
    toc: toc25,
    texts: txt25,
    demos: dm25,
  },
  'docs/guide/page-tab': {
    frontmatter: fm26,
    toc: toc26,
    texts: txt26,
    demos: dm26,
  },
  'docs/notes/cli-yarn': {
    frontmatter: fm27,
    toc: toc27,
    texts: txt27,
    demos: dm27,
  },
  'docs/notes/cli-npm': {
    frontmatter: fm28,
    toc: toc28,
    texts: txt28,
    demos: dm28,
  },
  'docs/notes/webpack': {
    frontmatter: fm29,
    toc: toc29,
    texts: txt29,
    demos: dm29,
  },
  'docs/notes/vscode': {
    frontmatter: fm30,
    toc: toc30,
    texts: txt30,
    demos: dm30,
  },
  'docs/guide/index': {
    frontmatter: fm31,
    toc: toc31,
    texts: txt31,
    demos: dm31,
  },
  'docs/notes/array': {
    frontmatter: fm32,
    toc: toc32,
    texts: txt32,
    demos: dm32,
  },
  'docs/notes/index': {
    frontmatter: fm33,
    toc: toc33,
    texts: txt33,
    demos: dm33,
  },
  'docs/words/index': {
    frontmatter: fm34,
    toc: toc34,
    texts: txt34,
    demos: dm34,
  },
  'docs/guide/i18n': {
    frontmatter: fm35,
    toc: toc35,
    texts: txt35,
    demos: dm35,
  },
  'docs/guide/faq': {
    frontmatter: fm36,
    toc: toc36,
    texts: txt36,
    demos: dm36,
  },
  'docs/notes/git': {
    frontmatter: fm37,
    toc: toc37,
    texts: txt37,
    demos: dm37,
  },
  'docs/notes/vue': {
    frontmatter: fm38,
    toc: toc38,
    texts: txt38,
    demos: dm38,
  },
  'docs/words/cn': {
    frontmatter: fm39,
    toc: toc39,
    texts: txt39,
    demos: dm39,
  },
  'docs/words/en': {
    frontmatter: fm40,
    toc: toc40,
    texts: txt40,
    demos: dm40,
  },
  'docs/index': {
    frontmatter: fm41,
    toc: toc41,
    texts: txt41,
    demos: dm41,
  },
}

// generate demos data in runtime, for reuse route.id to reduce bundle size
export const demos = Object.entries(filesMeta).reduce((acc, [id, meta]) => {
  // append route id to demo
  Object.values(meta.demos).forEach((demo) => {
    demo.routeId = id;
  });
  // merge demos
  Object.assign(acc, meta.demos);

  // remove demos from meta, to avoid deep clone demos in umi routes/children compatible logic
  delete meta.demos;

  return acc;
}, {});
